export default {

  bind: function (el, binding) {
    el.resizeHeightWatcher = function(e) {
      const watched = document.getElementById(binding.value)
      let h = 0;
      if (!!watched) {
        h = watched.offsetHeight
      }
      el.setAttribute('style', 'max-height: calc(100vh - ' + h + 'px);')
    }
    window.addEventListener('resize', el.resizeHeightWatcher)
  },

  inserted: function(el) {
    if (!!el.resizeHeightWatcher) {
      el.resizeHeightWatcher()
    }
  },

  unbind: function (el) {
    window.removeEventListener('resize', el.resizeHeightWatcher)
  }
}
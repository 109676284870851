import filter from '@/js/util/filter'
import EventBus from '@/js/util/EventBus'

export default {
  name: 'AppFilterCheckbox',
  inject: { filter },
  props: {
    taxonmy: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      checked: false,
    }
  },

  created() {
    // break this out?
    EventBus.$on('reset:filters', () => {
      this.checkCheckbox(false)
      // clear provider selected value
      this.createFilters(null)
    })
    this.filter.$emit('create:checkboxes', this)
  },

  methods: {
    createFilters(item) {
      this.filter.$emit('create:filters', item)
    },
    checkCheckbox(checked) {
      this.checked = checked
      // dom el
      this.$el.checked = checked
      this.createFilters({ id: !checked ? null : Number(this.$el.name), key: this.taxonmy })
    },
    handleChange() {
      this.checkCheckbox(this.$el.checked)
    },
  },

  render(createElement) {
    const { name: componentName } = this.$options
    const elementData = {
      class: { [`${componentName}--checked`]: this.checked },
      staticClass: componentName,
      on: { change: this.handleChange },
      attrs: {
        type: 'checkbox',
        'aria-checked': this.checked.toString(),
      },
    }
    return createElement('input', elementData, this.$slots.default)
  },
}

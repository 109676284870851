import Hammer from 'hammerjs'
import carousel from '@/js/util/carousel'

export default {
  name: 'CarouselContainerMultiple',
  inject: { carousel },

  data: () => ({
    defaultClass: undefined,
  }),

  mounted() {
    [this.defaultClass] = this.$el.classList

    const hammer = new Hammer(this.$el)

    hammer.on('swipeleft', () => this.setCarouselDirection('next'))
    hammer.on('swiperight', () => this.setCarouselDirection('previous'))
  },

  created() {
    this.emitCarouselEvent('create:items', this)
  },

  computed: {
    currentItemIndex() {
      return this.carousel.currentItemIndex
    },
    item0() {
      return this.carousel.currentItem === this
    },
    nextItem1() {
      return this.findCurrentItem(this.currentItemIndex + 1) === this
    },
    nextItem2() {
      return this.findCurrentItem(this.currentItemIndex + 2) === this
    },
    nextItem3() {
      return this.findCurrentItem(this.currentItemIndex + 3) === this
    },
    previousItem1() {
      return this.findCurrentItem(this.currentItemIndex - 1) === this
    },
    previousItem2() {
      return this.findCurrentItem(this.currentItemIndex - 2) === this
    },
    previousItem3() {
      return this.findCurrentItem(this.currentItemIndex - 3) === this
    },
  },

  methods: {
    findCurrentItem(index) {
      const { items } = this.carousel
      const { length } = items
      // break this out?
      return items[(((index) % length) + length) % length]
    },

    emitCarouselEvent(...events) {
      this.carousel.$emit(...events)
    },

    setCarouselDirection(direction) {
      this.emitCarouselEvent('carousel:direction', direction)
    },
  },

  render(createElement) {
    const elementData = {
      directives: [{
        name: 'show',
        value: this.item0
          || this.nextItem1
          || this.nextItem2
          || this.nextItem3
          || this.previousItem1
          || this.previousItem2
          || this.previousItem3,
      }],
      class: {
        [`${this.defaultClass}--isItem0`]: this.item0,
        [`${this.defaultClass}--isNextItem1`]: this.nextItem1,
        [`${this.defaultClass}--isNextItem2`]: this.nextItem2,
        [`${this.defaultClass}--isNextItem3`]: this.nextItem3,
        [`${this.defaultClass}--isPreviousItem1`]: this.previousItem1,
        [`${this.defaultClass}--isPreviousItem2`]: this.previousItem2,
        [`${this.defaultClass}--isPreviousItem3`]: this.previousItem3,
      },
    }
    // keeping this here for reference
    // const isShown = this.item0
    //   || this.nextItem1
    //   || this.nextItem2
    //   || this.nextItem3
    //   || this.previousItem1
    //   || this.previousItem2
    //   || this.previousItem3

    // return isShown ? createElement('li', elementData, this.$slots.default) : undefined
    return createElement('li', elementData, this.$slots.default)
  },
}
<template>
  <button
    type="button"
    :aria-controls="alertId"
    @click="click()"
  >
    <slot />
  </button>
</template>

<script>
import eventBus from '@/js/util/EventBus'

export default {
  props: {
    alertId: {
      type: String,
      required: true,
    },
  },
  methods: {
    click() {
      eventBus.$emit('alert:acknowledged', {
        controls: this.alertId
      })
    }
  },
}
</script>

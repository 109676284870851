import filter from '@/js/util/filter'
import EventBus from '@/js/util/EventBus'

export default {
  inject: { filter },
  props: {
    taxonmy: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },

  created() {
    EventBus.$on('reset:filters', () => {
      this.$el.value = null
      // clear provider selected value
      this.createFilters({id: this.id, key: this.taxonmy})
    })
    this.createFilters({id: this.id, key: this.taxonmy})
  },

  methods: {
    createFilters(item) {
      this.filter.$emit('create:filters', item)
    }
  },

  render(createElement) {
    return createElement('aside')
  },
}

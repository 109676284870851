import filter from '@/js/util/filter'

export default {
  inject: { filter },
  props: {
    relatedItem: {
      type: [Object, String],
      required: true,
    },
  },

  created() {
    const { relatedItem } = this
    if (typeof relatedItem === 'object') {
      this.emitEvent({
        id: relatedItem.id,
        name: relatedItem.name,
      })
      return
    }
    this.emitEvent({ id: null, name: relatedItem })
  },

  methods: {
    emitEvent(item) {
      this.filter.$emit('create:related', item)
    },
  },

  render(createElement) {
    return createElement('option', this.$slots.default)
  },
}

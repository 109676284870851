import carousel from '@/js/util/carousel'

export default {
  inject: { carousel },
  
  methods: {
    handleButtonClick() {
      this.carousel.$emit('carousel:toggle-autocycle')
    },
  },

  render(createElement) {
    const elementData = {
      on: {
        click: this.handleButtonClick,
      }
    }
    return createElement('button', elementData, this.$slots.default)
  },
}

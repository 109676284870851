import filter from '@/js/util/filter'
import propsTag from '@/js/util/propsTag'

export default {
  inject: { filter },
  props: { ...propsTag },
  render(createElement) {
    const elementData = {
      directives: [{
        name: 'show',
        value: this.filter.filteredItemsSearch.length === 0,
      }],
    }
    return createElement(this.tag, elementData, this.$slots.default)
  }
}

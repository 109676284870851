<template>
  <button
    type="button"
    :aria-controls="disclosureId"
    :aria-expanded="expanded.toString()"
    @click="click()"
  >
    <slot />
  </button>
</template>

<script>
import eventBus from '@/js/util/EventBus'

export default {
  props: {
    disclosureId: {
      type: String,
      required: true,
    },
    initiallyExpanded: {
      type: Boolean,
      default: false,
    },
    toggleGroup: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      expanded: this.initiallyExpanded,
    }
  },
  created() {
    eventBus.$on('created:container', this.createdContainer)
    eventBus.$on('toggle', this.toggle)
  },
  methods: {
    createdContainer(disclosureId) {
      eventBus.$emit('toggle', {
        expanded: this.expanded,
        controls: disclosureId,
        toggleGroup: this.toggleGroup
      })
    },
    toggle({ controls, expanded, toggleGroup }) {
      if (controls == this.disclosureId) {
        this.expanded = expanded
      } else {
        if (this.toggleGroup == toggleGroup) {
          this.expanded = false
        }
      }
    },
    click() {
      eventBus.$emit('toggle', {
        expanded: !this.expanded,
        controls: this.disclosureId,
        toggleGroup: this.toggleGroup
      })
    }
  },
}
</script>

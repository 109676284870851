import date from '@/js/util/date'
import filter from '@/js/util/filter'

export default {
  name: 'DateFilterButton',
  inject: { date, filter },
  props: {
    token: {
      type: String,
      default: null,
    },
    emitsEvent: {
      type: String,
      requried: true,
    }
  },

  computed: {
    isCurrent() {
      return this.date.currentToken === this.token
    },
  },

  render(createElement) {
    const { name: componentName } = this.$options
    const elementData = {
      on: {
        click: () => {
          this.date.$emit(`${this.emitsEvent}`, this.token)
          this.filter.$emit('create:filter.token', this.token)
        },
      },
      staticClass: componentName,
      class: { [`${componentName}--current`]: this.isCurrent },
    }
    return createElement('button', elementData, this.$slots.default)
  },
}

<template>
  <transition
    :css="false"
    @enter="enter"
  >
    <slot />
  </transition>
</template>

<script>
// If you need to create a leave transition:
// Add `@leave` event handler in the template and a corresponding method.

import { TweenLite, Power4 } from 'gsap'

export default {
  methods: {
    enter(el, done) {
      TweenLite.fromTo(
        el,
        0.5,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          ease: Power4.easeInOut,
          onComplete: done,
        },
      )
    },
  },
}
</script>

import 'whatwg-fetch'
import propsTag from '@/js/util/propsTag'


export default {
  props: {
    ...propsTag,

    endpoint: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      items: undefined,
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      const response = await (
        await window.fetch(this.endpoint)
      ).json()

      this.items = response
      // for use in AppFilter
      this.$emit('fetched', this.items)
    },
  },
  render(createElement) {
    return createElement(this.tag, this.$scopedSlots.default({ items: this.items }))
  },
}

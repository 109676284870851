import carousel from '@/js/util/carousel'
import propsTag from '@/js/util/propsTag'

export default {
  provide() {
    return { [carousel]: this }
  },
  props: {
    ...propsTag,
    autocycle: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 5
    },
  },

  data: () => ({
    items: [],
    currentItem: undefined,
    cycling: false,
    defaultClass: undefined,
  }),

  created() {
    // carousel direction has callback which
    // is a string prop either previous or next
    this.$on('carousel:direction', direction => this[`${direction}Item`]())

    // set item
    this.$on('set:item', this.setItem)
    // creates items
    this.$on('create:items', this.createItems)
    // toggles autocycle
    this.$on('carousel:toggle-autocycle', this.toggleAutocycle)
    if (this.autocycle) {
      this.startCycling()
    }
  },

  mounted() {
    [this.defaultClass] = this.$el.classList
  },

  computed: {
    currentItemIndex() {
      return this.items.indexOf(this.currentItem)
    },
  },

  methods: {
    setItem(item) {
      this.currentItem = item
    },

    createItems(item) {
      this.items.push(item)
      if (!this.currentItem) {
        this.setItem(item)
      }
    },

    nextItem() {
      this.carouselDirection(this.currentItemIndex + 1)
    },
    previousItem() {
      this.carouselDirection(this.currentItemIndex - 1)
    },

    carouselDirection(direction) {
      const { items } = this
      const { length } = items
      const currentItem = items[(((direction) % length) + length) % length]
      this.setItem(currentItem)
      this.resetCycleInterval()
    },

    toggleAutocycle() {
      if (this.cycling) {
        this.stopCycling()
      } else {
        this.nextItem()
        this.startCycling()
      }
    },

    startCycling() {
      this.cycling = true;
      this.cycleInterval = setInterval(() => {
        this.nextItem();
      }, this.duration * 1000)
    },

    stopCycling() {
      this.cycling = false;
      clearInterval(this.cycleInterval)
    },

    resetCycleInterval() {
      if (this.cycling) {
        this.stopCycling()
        this.startCycling()
      }
    }
  },

  render(createElement) {
    const elementData = {
      class: {
        [`${this.defaultClass}--is-cycling`]: this.cycling,
      }
    }
    return createElement(this.tag, elementData, this.$slots.default)
  },
}

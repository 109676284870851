<script>
import eventBus from '@/js/util/EventBus'

import DisclosureTransition from '../disclosure/DisclosureTransition'

export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    frequency: {
      type: String,
      default: 'once'
    },
    alertId: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      default: 'show',
      validate: value => [
        'if',
        'show',
      ].includes(value),
    },
  },
  data: () => ({
    expanded: true,
  }),
  computed: {
    storage: function() {
      return this.frequency == 'session' ? sessionStorage : localStorage;
    }
  },
  created() {
    this.checkAcknowledgement(this.alertId)
    eventBus.$on('alert:acknowledged', this.acknowledge)
  },
  methods: {
    acknowledge({ controls }) {
      if (controls === this.alertId) {
        this.setAcknowledgement(this.alertId);
      }
    },
    checkAcknowledgement(id) {
      const stored = this.storage.getItem('alerts')
      const parsed = stored ? JSON.parse(stored) : {}
      this.expanded = parsed[id] !== true
    },
    setAcknowledgement(id) {
      const stored = this.storage.getItem('alerts')
      const parsed = stored ? JSON.parse(stored) : {}
      this.expanded = false
      this.storage.setItem('alerts', JSON.stringify({
        ...parsed,
        [id]: true
      }))
    }
  },
  render(createElement) {
    const { expanded } = this
    const data = {
      class: { expanded },
    }
    return createElement(this.tag, data, this.$slots.default)
  },
}
</script>

import propsTag from '@/js/util/propsTag'

export default {
  props: {
    ...propsTag,
  },

  data() {
    return {
      width: window.innerWidth,
    }
  },

  mounted() {
    window.addEventListener('resize', this.setWidth)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setWidth)
  },

  methods: {
    setWidth() {
      this.width = window.innerWidth
    },
  },

  render(createElement) {
    return createElement(this.tag, this.$scopedSlots.default({ width: this.width }))
  },
}

import filter from '@/js/util/filter'

export default {
  name: 'AppFilterAmountShownButton',
  inject: { filter },
  render(createElement) {
    const { filter } = this
    const elementData = {
      staticClass: this.$options.name,
      directives: [{
        name: 'show',
        value: filter.amountShown < filter.filteredItemsSearch.length,
      }],
      on: { click: () => filter.$emit('create:amount') }
    }
    return createElement('button', elementData, this.$slots.default)
  },
}

import AppFilter from '@/js/components/AppFilter'
import AppFilterError from '@/js/components/AppFilterError'
import AppFilterEvents from '@/js/components/AppFilterEvents'
import AppFilterInput from '@/js/components/AppFilterInput'
import AppFilterFetch from '@/js/components/AppFilterFetch'
import AppFilterSelect from '@/js/components/AppFilterSelect'
import AppFilterPreselect from '@/js/components/AppFilterPreselect'
import AppFilterPrecheck from '@/js/components/AppFilterPrecheck'
import AppFilterCheckbox from '@/js/components/AppFilterCheckbox'
import AppFilterResetButton from '@/js/components/AppFilterResetButton'
import AppFilterRelatedItem from '@/js/components/AppFilterRelatedItem'
import AppFilterDateFormater from '@/js/components/AppFilterDateFormater'
import AppFilterSelectOption from '@/js/components/AppFilterSelectOption'
import AppFilterAmountShownButton from '@/js/components/AppFilterAmountShownButton'

export default {
  AppFilter,
  AppFilterError,
  AppFilterEvents,
  AppFilterInput,
  AppFilterFetch,
  AppFilterSelect,
  AppFilterPreselect,
  AppFilterPrecheck,
  AppFilterCheckbox,
  AppFilterResetButton,
  AppFilterRelatedItem,
  AppFilterDateFormater,
  AppFilterSelectOption,
  AppFilterAmountShownButton,
}

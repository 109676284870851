import EventBus from '@/js/util/EventBus'

export default {
  name: 'AppFilterResetButton',

  render(createElement) {
    const elementData = {
      staticClass: this.$options.name,
      on: { click: () => EventBus.$emit('reset:filters') }
    }
    return createElement('button', elementData, this.$slots.default)
  },
}

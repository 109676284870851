import Hammer from 'hammerjs'

import carousel from '@/js/util/carousel'

import CarouselContainerAnimation from './CarouselContainerAnimation'

export default {
  inject: { carousel },

  // Note: this component assumes it will always be an li element
  // in the event that you need to change it you hard code it or
  // make it dynamic by using a prop and giving it a default value.

  // props: {
  //   tag: {
  //     type: String,
  //     default: 'li',
  //   },
  // },

  props: {
    animationType: {
      type: String,
      default: 'fadeInLeft',
    },
  },

  data: () => ({
    defaultClass: undefined,
  }),

  mounted() {
    [this.defaultClass] = this.$el.classList

    const hammer = new Hammer(this.$el)

    hammer.on('swipeleft', () => this.setCarouselDirection('next'))
    hammer.on('swiperight', () => this.setCarouselDirection('previous'))
  },

  created() {
    this.emitEvent('create:items', this)
  },

  computed: {
    evaluateItem() {
      return this.carousel.currentItem === this
    },
  },

  methods: {
    emitEvent(...events) {
      this.carousel.$emit(...events)
    },

    handleEvent() {
      this.emitEvent('set:item', this)
    },

    setCarouselDirection(direction) {
      this.emitEvent('carousel:direction', direction)
    },
  },

  render(createElement) {
    const { evaluateItem } = this
    const elementData = {
      attrs: {
        'aria-hidden': (!evaluateItem).toString(),
      },
      directives: [{
        name: 'show',
        value: evaluateItem,
      }],
      class: {
        // dynamically concatenates modifer class with
        // the first class on the component createing
        // a semantic modifier class. Delete this and
        // related code if you do not need.
        [`${this.defaultClass}--isActive`]: evaluateItem,
      },
    }

    const createsChildElement = createElement(CarouselContainerAnimation, {
      props: { animationType: this.animationType },
      key: `BaseCarouselListItem_${this.carousel.currentItemIndex}`,
    }, this.$slots.default)

    // if this component needs to be dynamic swap the 'li' with
    // this.tag
    return createElement('li', elementData, [createsChildElement])
  },
}

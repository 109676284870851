<template>
  <component 
    :is="tag"
    :class="scrollDirection"
  >
    <slot />
  </component>
</template>

<script>
import tag from '@/js/util/propsTag'

export default {
  props: {
    ...tag,
    topBuffer: {
      type: Number,
      default: 100
    },
    scrollBuffer: {
      type: Number,
      default: 50
    },
    scrollingDownClass: {
      type: String,
      default: 'scrolling-down'
    }
  },

  data: function() {
    return {
      last: 0,
      scrollingDown: false
    }
  },

  computed: {
    scrollDirection: function() {
      return {
        [this.scrollingDownClass]: this.scrollingDown
      }
    }
  },

  created () {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll(event) {
      const current = window.pageYOffset;
      if (current > this.topBuffer) {
        if (Math.abs(current - this.last) > this.scrollBuffer) {
          this.scrollingDown = current > this.last
          this.last = current
        }
      } else {
        this.scrollingDown = false;
      }
    },
  }
}
</script>

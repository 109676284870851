import filter from '@/js/util/filter'
import EventBus from '@/js/util/EventBus'

export default {
  name: 'AppFilterInput',
  inject: { filter },
  props: {
    placeholderText: {
      type: String,
      default: 'Search',
    },
  },

  data() {
    return {
      componentName: this.$options.name,
    }
  },

  created() {
    EventBus.$on('reset:filters', () => {
      // clear search in provider also
      this.createSearch('')
      this.$refs[this.componentName].value = null
    })
  },

  methods: {
    createSearch(search) {
      this.filter.$emit('create:search', search)
    },
    handleInput({ target: { value } }) {
      this.createSearch(value)
    },

    createChild(createElement) {
      const { componentName } = this
      const elementData = {
        ref: componentName,
        staticClass: componentName,
        on: {
          input: this.handleInput,
        },
        attrs: {
          type: 'text',
          value: this.filter.search,
          placeholder: this.placeholderText,
        },
      }
      return createElement('input', elementData)
    },
  },

  render(createElement) {
    return createElement('label', [this.$slots.default, this.createChild(createElement)])
  },
}

import filter from '@/js/util/filter'
import EventBus from '@/js/util/EventBus'

export default {
  inject: { filter },
  props: {
    taxonmy: {
      type: String,
      required: true,
    },
    ids: {
      type: Array,
      required: true,
    },
  },

  created() {
    EventBus.$on('reset:filters', () => {
      this.$el.value = null
      // clear provider selected value
      this.precheck()
    })
    this.precheck()
  },

  methods: {
    precheck(ids) {
      this.ids.forEach(id => {
        this.filter.$emit('create:checkboxes', {
          $attrs: { name: id },
          checked: true
        })
        this.filter.$emit('create:filters', {
          id,
          key: this.taxonmy
        })
      })
    }
  },

  render(createElement) {
    return createElement('aside')
  },
}

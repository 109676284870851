import filter from '@/js/util/filter'
import propsTag from '@/js/util/propsTag'

export default {
  name: 'AppFilterRelatedItem',
  inject: { filter },
  props: {
    ...propsTag,

    relatedItem: {
      type: Number,
      required: true,
    },
  },

  computed: {
    isRelated() {
      return this.filter.related.filter(({ id }) => id === this.relatedItem)
    },
  },

  render(createElement) {
    const elementData = {
      staticClass: this.$options.name,
    }
    return createElement(this.tag, elementData, this.$scopedSlots.default({ items: this.isRelated }))
  },
}

import date from '@/js/util/date'
import filter from '@/js/util/filter'
import propsTag from '@/js/util/propsTag'

// time lib
import moment from 'moment'

// helper fn
const capitalize = str => `${str.charAt(0).toUpperCase().concat(str.slice(1))}`

export default {
  name: 'DateFilter',
  inject: { filter },
  provide() {
    return {
      [date]: this,
    }
  },
  props: { ...propsTag },

  data() {
    return {
      currentDate: undefined,
      currentToken: 'upcoming',
      formatedCurrentDate: undefined,
    }
  },

  mounted() {
    this.setCurrentDate(moment().format('MMMM Do YYYY'))
    this.setFormatedCurrentDate(moment().format('YYYY-MM-DD'))
  },

  watch: {
    endDate: 'emitFilterEvent',
  },

  computed: {
    endDate() {
      return moment(this.currentDate, 'MMMM Do YYYY').add(1, this.currentToken).format('MMMM Do YYYY')
    },
    formatedEndDate() {
      return moment(this.formatedCurrentDate, 'YYYY-MM-DD').add(1, this.currentToken).format('YYYY-MM-DD')
    }
  },

  created() {
    this.$on('increment:date', this.incrementDate)
    this.$on('decrement:date', this.decrementDate)
    this.$on('set:current.token', this.setCurrentToken)
  },

  methods: {
    // start date
    setCurrentDate(currentDate) {
      this.currentDate = currentDate
    },
    setFormatedCurrentDate(formatedDate) {
      this.formatedCurrentDate = formatedDate
    },
    setCurrentToken(token) {
      this.currentToken = token
    },
    incrementDate(token) {
      const { currentDate } = this
      this.setCurrentDate(moment(currentDate, 'MMMM Do YYYY').add(1, token).format('MMMM Do YYYY'))
      this.setFormatedCurrentDate(moment(this.formatedCurrentDate, 'YYYY-MM-DD').add(1, token).format('YYYY-MM-DD'))
    },
    decrementDate(token) {
      const { currentDate } = this
      this.setCurrentDate(moment(currentDate, 'MMMM Do YYYY').subtract(1, token).format('MMMM Do YYYY'))
      this.setFormatedCurrentDate(moment(this.formatedCurrentDate, 'YYYY-MM-DD').subtract(1, token).format('YYYY-MM-DD'))
    },

    emitFilterEvent() {
      this.filter.$emit('create:filter.date', {
        endDate: this.formatedEndDate,
        currentDate: this.formatedCurrentDate,
      })
    },
  },

  render(createElement) {
    const { name: componentName } = this.$options
    const slotData = {
      endDate: this.endDate,
      currentDate: this.currentDate,
      token: capitalize(this.currentToken),
    }
    const elementData = {
      staticClass: componentName,
      class: { [`${componentName}--hide-controls`]: this.currentToken === 'upcoming' },
    }
    return createElement(this.tag, elementData, this.$scopedSlots.default(slotData))
  },
}

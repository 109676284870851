import propsTag from '@/js/util/propsTag'

import moment from 'moment'

export default {
  props: {
    ...propsTag,

    date: {
      type: String,
      required: true,
    },
  },

  render(createElement) {
    const { date } = this
    const mmt = moment(date);
    return createElement(this.tag, this.$scopedSlots.default({
      day: mmt.format('DD'),
      month: mmt.format('MMM'),
    }))
  },
}

<template>
  <div
    :class="{ 'fit-height': !fitWidth, 'fit-width': fitWidth }"
    :style="styles"
  >
    <slot />
  </div>
</template>

<script>

export default {
  props: {
    w: { type: Number, default: 16 },
    h: { type: Number, default: 9 },
  },
  data: function() {
    return {
      actual: { w: 0, h: 0 }
    }
  },
  computed: {
    fitWidth: function() {
      const desired = this.w / this.h;
      const actual = this.actual.w / this.actual.h;
      return desired < actual;
    },
    styles: function() {
      if (this.fitWidth) {
        return {
          width: '100%',
          height: (this.actual.w / this.w * this.h) + 'px'
        };
      }
      return {
        width: (this.actual.h / this.h * this.w) + 'px',
        height: '100%'
      };
    }
  },
  mounted: function() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize: function(e) {
      this.actual = {
        w: this.$el.parentElement.clientWidth,
        h: this.$el.parentElement.clientHeight
      }
    }
  }
}
</script>

import filter from '@/js/util/filter'
import propsTag from '@/js/util/propsTag'

// AppFilterFetch to get items

const itemMerge = (items, otherItems) => {
  const store = []
  items.forEach(item => {
    otherItems.forEach(otherItem => {
      if (item.id === otherItem.id) {
        // could be dynamic
        const { program_format } = otherItem.acf
        store.push({ ...item, program_format })
      }
    })
  })
  return store
}

export default {
  provide() {
    return {
      [filter]: this,
    }
  },
  props: {
    ...propsTag,

    // for load more
    amount: {
      type: Number,
      default: 9,
    },
    endpoint: {
      type: String,
      required: true,
    },

    // determines what type
    // of filter to build
    mode: {
      type: String,
      default: 'single',
      validator: mode => [
        'single',
        'multiple',
      ].includes(mode),
    },

    // should not need
    otherItems: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      filter: {},

      items: [],
      search: '',
      checkboxes: [],
      amountShown: this.amount,

      related: [],
    }
  },

  created() {
    this.$on('create:search', this.createSearch)
    this.$on('create:filters', this.createFilters)
    this.$on('create:amount', this.createAmountShown)
    this.$on('create:checkboxes', this.createCheckboxes)
    this.$on('create:related', this.createRelatedFilters)
  },

  watch: {
    // if all checkboxes are
    // empty reset filter object
    checkboxesChecked: 'createCheckedReset',
  },

  computed: {
    checkboxesChecked() {
      return this.checkboxes.filter(({ checked }) => checked)
    },
    checkedTaxonmies() {
      return this.checkboxesChecked.map(({ $attrs: { name } }) => name)
    },

    filtersSingle() {
      return Object.entries(this.filter).map(([key, id]) => ({ key, id }))
    },
    filteredItemsSingle() {
      return this.items.filter(item => this.filtersSingle.every(({ key, id }) => item[key].includes(id)))
    },

    // for mode multiple
    filteredItemsMultiple() {
      if (this.mode === 'single') {
        return undefined
      }
      return this.items.filter(item => {
        const [dynamicKey] = Object.keys(this.filter)
        if (dynamicKey === undefined) {
          return item
        }
        return (item[`${dynamicKey}`] || []).find(child => this.filter[`${dynamicKey}`].includes(child))
      })
    },

    filterSearchMode() {
      return {
        single:  this.filteredItemsSingle,
        multiple: this.filteredItemsMultiple,
      }[this.mode]
    },
    filteredItemsSearch() {
      return this.filterSearchMode.filter(({ title: { rendered } }) =>
        rendered
          .toLowerCase()
          .includes(this.search.toLowerCase()),
        )
    },
  },

  methods: {
    createItems(items) {
      this.items = items

      const { otherItems } = this
      if (otherItems === null) {
        return
      }
      this.items = itemMerge(items, otherItems)
    },
    createSearch(search) {
      this.search = search
    },
    createFilters(item) {
      if (item === null) {
        this.filter = {}
        return
      }
      const { key, id } = item
      const { filter: isFilter } = this
      // builds filter that allows
      // multiple filters
      if (this.mode === 'multiple') {
        this.$set(isFilter, key, this.checkedTaxonmies)
        return
      }
      const params = [isFilter, key, id]
      // using $delete and $set because
      // of vues change detection caveats
      if (id === null) {
        this.$delete(...params)
        return
      }
      this.$set(...params)
    },
    createCheckboxes(checkbox) {
      this.checkboxes.push(checkbox)
    },
    createAmountShown() {
      this.amountShown += this.amount
    },
    createCheckedReset(checked) {
      if (checked && checked.length) {
        return
      }
      this.createFilters(null)
    },

    createRelatedFilters(related) {
      this.related.push(related)
    },
  },

  render(createElement) {
    const slotData = {
      amountShown: this.amountShown,
      items: this.filteredItemsSearch,
    }
    return createElement('AppFilterFetch', {
      on: { fetched: this.createItems },
      props: {
        tag: this.tag,
        endpoint: this.endpoint,
      },
    }, this.$scopedSlots.default(slotData))
  }
}

import TabsContainer from './TabsContainer'
import TabsContainerTablist from './TabsContainerTablist'
import TabsContainerTablistTab from './TabsContainerTablistTab'
import TabsContainerTabPanel from './TabsContainerTabPanel'
import TabsTransition from './TabsTransition'

export default {
  TabsContainer,
  TabsContainerTablist,
  TabsContainerTablistTab,
  TabsContainerTabPanel,
  TabsTransition,
}

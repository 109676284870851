import filter from '@/js/util/filter'
import propsTag from '@/js/util/propsTag'

import moment from 'moment'

export default {
  provide() {
    return {
      [filter]: this,
    }
  },
  props: {
    ...propsTag,

    // for load more
    amount: {
      type: Number,
      default: 9,
    },
    endpoint: {
      type: String,
      required: true,
    },

    // should not need
    otherItems: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      filter: {},

      items: [],
      search: '',
      checkboxes: [],
      amountShown: this.amount,

      // change
      endDate: undefined,
      currentDate: undefined,
      currentToken: 'upcoming',
    }
  },

  created() {
    this.$on('create:search', this.createSearch)
    this.$on('create:filters', this.createFilters)
    this.$on('create:amount', this.createAmountShown)
    this.$on('create:checkboxes', this.createCheckboxes)
    this.$on('create:filter.date', this.createFilterDate)
    this.$on('create:filter.token', this.createFilterToken)
  },

  watch: {
    checkboxesChecked: 'createCheckedReset',
  },

  computed: {
    checkboxesChecked() {
      return this.checkboxes.filter(({ checked }) => checked)
    },
    checkedTaxonmies() {
      return this.checkboxesChecked.map(({ $attrs: { name } }) => name)
    },

    // for mode multiple
    filteredItemsMultiple() {
      // check mode
      return this.items.filter(item => {
        const [dynamicKey] = Object.keys(this.filter)
        if (dynamicKey === undefined) {
          return item
        }
        return (item[`${dynamicKey}`] || []).find(child => this.filter[`${dynamicKey}`].includes(child))
      })
    },

    filteredItemsByUpcomingDate() {
      return this.filteredItemsMultiple.filter(({ acf: { custom_date } }) =>
        moment(moment(custom_date).format('YYYY-MM-DD')).isAfter(moment().format('YYYY-MM-DD')),
      )
    },
    filteredItemsByDateRange() {
      return this.filteredItemsMultiple.filter(({ acf: { custom_date } }) =>
        moment(moment(custom_date).format('YYYY-MM-DD')).isBetween(this.currentDate, this.endDate, null, '[]'),
      )
    },

    filterSearchMode() {
      return this.currentToken === 'upcoming' ? this.filteredItemsByUpcomingDate : this.filteredItemsByDateRange
    },
    filteredItemsSearch() {
      return this.filterSearchMode.filter(({ title: { rendered } }) =>
        rendered
          .toLowerCase()
          .includes(this.search.toLowerCase()),
        )
    },
  },

  methods: {
    createFilterToken(token) {
      this.currentToken = token
    },
    createFilterDate({ endDate, currentDate }) {
      this.endDate = endDate
      this.currentDate = currentDate
    },

    createItems(items) {
      this.items = items.sort((a, b) =>
        moment(a.acf.custom_date).format('YYYYMMDD') -
        moment(b.acf.custom_date).format('YYYYMMDD'),
      )
    },
    createSearch(search) {
      this.search = search
    },
    createFilters(item) {
      if (item === null) {
        this.filter = {}
        return
      }
      this.$set(this.filter, item.key, this.checkedTaxonmies)
    },
    createCheckboxes(checkbox) {
      this.checkboxes.push(checkbox)
    },
    createAmountShown() {
      this.amountShown += this.amount
    },
    createCheckedReset(checked) {
      if (checked && checked.length) {
        return
      }
      this.createFilters(null)
    },

    createRelatedFilters(related) {
      this.related.push(related)
    },
  },

  render(createElement) {
    const slotData = {
      amountShown: this.amountShown,
      // add sort here
      items: this.filteredItemsSearch,
    }
    return createElement('AppFilterFetch', {
      on: { fetched: this.createItems },
      props: {
        tag: this.tag,
        endpoint: this.endpoint,
      },
    }, this.$scopedSlots.default(slotData))
  }
}

import filter from '@/js/util/filter'
import EventBus from '@/js/util/EventBus'

export default {
  inject: { filter },
  props: {
    taxonmy: {
      type: String,
      required: true,
    },
    relatedData: {
      type: Object,
      default: () => ({}),
    },
  },

  created() {
    EventBus.$on('reset:filters', () => {
      this.$el.value = null
      // clear provider selected value
      this.createFilters(null)
    })
  },

  methods: {
    createFilters(item) {
      this.filter.$emit('create:filters', item)
    }
  },

  render(createElement) {
    return createElement('select', {
      on: {
        change: ({ target: { value } }) => {
          let isValue = value
          if (/\d/.test(value)) {
            isValue = Number(value)
          } else if (value === 'null') {
            isValue = null
          }
          this.createFilters({ id: isValue, key: this.taxonmy })
        },
      },
    }, this.$slots.default)
  },
}

<script>
import EventBus from '@/js/util/EventBus'
import propsTag from '@/js/util/propsTag'
import propsModalId from '@/js/util/propsModalId'

import ModalContainerDialog from './ModalContainerDialog'

export default {
  provide() {
    return { modal: this }
  },
  props: {
    ...propsTag,
    // required prop for matching the ModalButton
    // annd ModalContainer
    ...propsModalId,

    // pass in the string 'if' if you need to to add and
    // remove this element from the DOM the default is
    // show which adds v-show making it display: none;
    mode: {
      type: String,
      default: 'show',
      validator: mode => [
        'if',
        'show',
      ].includes(mode),
    },
    initiallyExpanded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      expanded: false,
      // on created modalDialog becomes the
      // ModalContainerDialog element so we
      // can bind emit for event emition
      modalDialog: undefined,
    }
  },

  created() {
    EventBus.$on('toggle:modal', this.maybeToggleModal)
  },
  mounted() {
    if (this.initiallyExpanded) {
      this.toggleModal()
    }
  },

  methods: {
    maybeToggleModal(buttonId) {
      if (buttonId !== this.modalId) {
        return
      }

      this.toggleModal()
    },

    toggleModal() {
      const { expanded } = this

      this.expanded = !expanded
      // <ModalContainer
      //   @modal-open="doOpenThing"
      //   @modal-close="doCloseThing"
      // >
      //   modal content
      // </ModalContainer>
      //
      // in the event that you need open to call a method
      // or perform some logic there are these open and close
      // hooks for use on the component. See the code above

      if (this.expanded) {
        document.getElementsByTagName("html")[0].classList.add('ModalContainer--open')
      } else {
        document.getElementsByTagName("html")[0].classList.remove('ModalContainer--open')
      }
      this.$emit(`modal-${expanded ? 'open' : 'close'}`)
    },

    createsFocusTrap(createElement, position) {
      return createElement('div', {
        attrs: { tabindex: 0 },
        on: { focus: () => this.modalDialog.$emit(`focus-${position}-descendant`) },
      })
    },

    createsModalButton(createElement) {
      const { modalButton } = this.$slots
      const elementData = {
        class: 'ModalButton--close',
        props: { modalId: this.modalId },
      }
      // We have access to ModalButton because it is
      // registered globally.
      return modalButton
        ? createElement('ModalButton', elementData, modalButton)
        : undefined
    },

    createsModalDialog(createElement) {
      const modalDialog = createElement(ModalContainerDialog, {
        on: {
          createsModalDialog: (dialog) => {
            this.modalDialog = dialog
          },
        },
      }, this.$slots.default)

      return [
        this.createsFocusTrap(createElement, 'first'),
        // optional named slot for ModalButton
        // passing the ModalButton through this slot
        // renders the ModalButton outside of the dialog
        // for different DOM structure
        this.createsModalButton(createElement),
        modalDialog,
        this.createsFocusTrap(createElement, 'last'),
      ]
    },
  },

  render(createElement) {
    const elementData = {
      staticClass: 'ModalContainer',
      on: {
        keyup: ({ keyCode }) => {
          if (keyCode !== 27) {
            return
          }

          this.toggleModal()
        },
      },
    }

    const { expanded, mode } = this

    if (mode === 'show') {
      elementData.directives = [{
        name: 'show',
        value: expanded,
      }]
    }

    const modalOverlay = createElement('div', {
      staticClass: 'ModalContainer__overlay',
      on: { click: () => EventBus.$emit('toggle:modal', this.modalId) },
    })
    const modalDialog = this.createsModalDialog(createElement)

    return mode === 'show' || expanded
      ? createElement(this.tag, elementData, [modalOverlay, modalDialog])
      : undefined
  },
}
</script>

<style lang="scss">
.ModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalContainer__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.5);
}

// should probably change
.ModalButton--close {
  $_button-position: 20px;
  position: fixed;
  top: $_button-position;
  right: $_button-position;
}

html.ModalContainer--open {
  overflow: hidden;
}

.ModalContainer,
.ModalContainer__overlay,
.ModalContainerDialog,
.ModalButton--close {
  z-index: 99999;
}

</style>

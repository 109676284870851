import Vue from 'vue'

// Sass
import '@/styles/main.scss'

// Post CSS
import 'postcss-object-fit-images'

// Pollyfills
import svg4everybody from 'svg4everybody'
import objectFitImages from 'object-fit-images'

// Tabs
import Tabs from '@/js/components/tabs/index'

// Modal
import Modal from '@/js/components/modal/index'

// Player
import Player from '@/js/components/player/index'

// Carousel
import Carousel from '@/js/components/carousel/index'

// Accordion
import Accordion from '@/js/components/accordion/index'

// Disclosure
import Disclosure from '@/js/components/disclosure/index'

// Alert
import Alert from '@/js/components/alert/index'

// App filter
import AppFilterIndex from '@/js/components/AppFilterIndex'

// A11y
import DiscernibleContent from '@/js/components/DiscernibleContent'

// Custom
import BackgroundVideoFiller from '@/js/components/BackgroundVideoFiller.vue'
import ScrollWatcher from '@/js/components/ScrollWatcher.vue'
import FixedItemsShim from '@/js/components/FixedItemsShim.vue'

// Window Width
import AppWindowWidth from '@/js/components/AppWindowWidth'

// Date Filter
import DateFilterIndex from '@/js/components/DateFilterIndex'

// Intersection Observer Polyfill
import 'intersection-observer'

import HeightWatcher from '@/js/directives/HeightWatcher'

Vue.config.productionTip = false

const Components = {
  ...Tabs,
  ...Modal,
  ...Player,
  ...Carousel,
  ...Accordion,
  ...Disclosure,
  ...Alert,
  ...AppFilterIndex,
  ...DateFilterIndex,
  AppWindowWidth,
  DiscernibleContent,
  BackgroundVideoFiller,
  ScrollWatcher,
  FixedItemsShim,
  BackgroundVideoFiller
}

Object.keys(Components).forEach(componentName => Vue.component(componentName, Components[componentName]))

const Directives = {
  HeightWatcher
}

Object.keys(Directives).forEach(directiveName => Vue.directive(directiveName, Directives[directiveName]))

new Vue({
  delimiters: ['<%', '%>'],
  mounted() {
    svg4everybody()
    objectFitImages()
  },
}).$mount('#app')

<template>
  <component
    :is="tag"
    :style="{ 'padding-bottom': shimHeight }"
  >
    <slot />
  </component>
</template>

<script>
import tag from '@/js/util/propsTag'

export default {
  props: {
    ...tag,
    include: {
      type: Array,
      default: () => []
    },
  },

  data: function() {
    return {
      shimHeight: '0'
    }
  },

  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize();
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize(event) {
      this.shimHeight = []
        .concat(...this.include
          .map(className => Array.prototype.slice
            .call(this.$el.getElementsByClassName(className), 0)
          )
        )
        .filter(el => getComputedStyle(el).display !== 'none')
        .map(el => el.offsetHeight)
        .reduce((result, value) => result + value, 0) + 'px'
    },
  }
}
</script>

<script>
import { TimelineLite, Back } from 'gsap'

import propsTag from '@/js/util/propsTag'

export default {
  name: 'ModalContainerDialog',
  inject: ['modal'],
  props: {
    ...propsTag,
  },

  data: () => ({
    timeline: new TimelineLite(),
  }),

  // Watches parent "ModalContainer" for when
  // its expanded and then calls the triggerAnimation method
  watch: {
    'modal.expanded': 'triggerAnimation',
  },

  mounted() {
    const { $el } = this

    this.timeline.fromTo(
      $el,
      0.8,
      {
        y: '+=50',
        opacity: 0,
      },
      {
        y: '0',
        opacity: 1,
        ease: Back.easeOut,
      },
    )
    // checks ModalContainer's default mode to
    // determine if animation is initially paused
    .paused(this.modal.mode === 'show')
  },

  created() {
    // breaking suggested emit string convention of using
    // colons and periods to separate strings like 'creates:modal.dialog'
    // because firing this as a callback on the component in a render
    // function like on: { createsModalDialog: this.handlerForEvent }
    // so it should be valid Javascipt
    this.$emit('createsModalDialog', this)

    this.$on('focus-last-descendant', this.focusLastDescendant)
    this.$on('focus-first-descendant', this.focusFirstDescendant)
  },

  methods: {
    triggerAnimation(expanded) {
      const { timeline } = this
      return expanded ? timeline.play() : timeline.pause(0)
    },

    focusFirstDescendant(el = this.$el) {
      return Array.from(el.children).find(child => (
        this.attemptFocus(child) || this.focusFirstDescendant(child)
      ))
    },

    focusLastDescendant(el = this.$el) {
      return Array.from(el.children).reverse().find(child => (
        this.attemptFocus(child) || this.focusLastDescendant(child)
      ))
    },
    attemptFocus(el) {
      el.focus()

      return el === document.activeElement
    },
  },

  render(createElement) {
    const elementData = {
      staticClass: 'ModalContainerDialog',
      attrs: {
        role: 'dialog',
        'aria-modal': 'true',
      },
    }

    return createElement(this.tag, elementData, this.$slots.default)
  },
}
</script>

<style lang="scss">
.ModalContainerDialog {
  width: 100%;
  max-width: 1000px;
  max-height: 90vh;
  background: #000;
}
</style>
